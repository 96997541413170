
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { IconWeight } from '@pixcap/ui-library/models/icons.interface';
	import { DEFAULT_ICON_COLOR, DEFAULT_ICON_WIDTH } from '@pixcap/ui-library/constants/theme.constants';

	@Component({
		name: 'IconPencilSimple',
	})
	export default class IconPencilSimple extends Vue {
		@Prop({ default: DEFAULT_ICON_WIDTH }) readonly width: number;
		@Prop() readonly height: number;
		@Prop({ default: 'var(--pixcap-neutral-700)' }) readonly fill: string;
		@Prop({ default: IconWeight.OUTLINE }) readonly weight: IconWeight;

		get isOutlineWeight() {
			return this.weight == IconWeight.OUTLINE;
		}
	}
