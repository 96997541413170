
	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		name: 'IconLoader',
	})
	export default class IconLoader extends Vue {
		@Prop({ default: 40 }) readonly size: number;
		@Prop({ default: 'url(#paint0_linear)' }) readonly fill: string;
	}
