
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { DEFAULT_ICON_COLOR, DEFAULT_ICON_WIDTH, DEFAULT_ICON_HEIGHT } from '@pixcap/ui-library/constants/theme.constants';

	@Component({
		name: 'IconExitScreen',
	})
	export default class IconExitScreen extends Vue {
		@Prop({ default: DEFAULT_ICON_WIDTH }) readonly width: number;
		@Prop({ default: DEFAULT_ICON_HEIGHT }) readonly height: number;
		@Prop({ default: DEFAULT_ICON_COLOR }) readonly fill: string;
	}
